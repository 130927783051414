html, body, #root, .makeStyles-root-1 {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;

  overflow: hidden;
  -webkit-overflow-scrolling: auto;

  user-select: none;
  touch-action: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.notification-container{
  background-color: rgba(255,140,0, 0.0);
  width: 400px;
  border-radius: 7px;
  position: absolute;
  margin: 20px;
  z-index: 1;
  left: 300px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #5f5f5f;
  font-family: 'Raleway', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald', sans-serif;
}

h1 {
  font-size: 1.5rem;
}