/* width */
#sbc::-webkit-scrollbar {
    width: 5px;
  }

/* Track */
#sbc::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e6eaf2;
  border-radius: 10px;
}

/* Handle */
#sbc::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

button:focus {
  outline: none;
}

hr{
  margin: 5px
}
