.react-autosuggest__container {
   min-width: 0;
  }
  
  .react-autosuggest__input {
    margin-top: 10px;
    font-size: 16px;
    border: none;
    padding-left: 7px;

  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    width: 60%;
    box-shadow: 0px 1px 3px 1px rgba(87,87,87,0.8), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(87,87,87,0.8);
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    overflow-y: auto;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 7px 10px;
    /* border-bottom: 1px solid black; */
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #95bddf;
  }